import { PostRepository } from "../../../core/posts/domain/PostRepository";
import PostImage from "../../../core/posts/domain/PostImage";
import { AxiosPostRepository } from "../../../core/posts/infrastructure/repository/AxiosPostRepository";
import { PostsCountGetter } from "../../../core/posts/application/use_cases/getPostsCount/PostsCountGetter";
import { toastr } from "react-redux-toastr";
import { PostCreator } from "../../../core/posts/application/use_cases/createPost/PostCreator";
import { AllPostsGetter } from "../../../core/posts/application/use_cases/getposts/AllPostsGetter";
import { PagePostsGetter } from "../../../core/posts/application/use_cases/getposts/PagePostsGetter";
import { PostDestroyer } from "../../../core/posts/application/use_cases/deletepost/PostDestroyer";
import { PostModifier } from "../../../core/posts/application/use_cases/modifypost/PostModifier";

const postRepository: PostRepository = new AxiosPostRepository();
const indexPosts = new AllPostsGetter(postRepository);
const getPostsPage = new PagePostsGetter(postRepository);
const createPost = new PostCreator(postRepository);
const postModifier = new PostModifier(postRepository);
const postDestroyer = new PostDestroyer(postRepository);
const postsCountGetter = new PostsCountGetter(postRepository);

const getImageArrayBuffer = async (url): Promise<ArrayBuffer> => {
  return (await fetch(url)).arrayBuffer().then((res) => res);
};

export const addPost = (
  _id: string,
  title: string,
  owner_id: string, // user id of owner
  subtitle: string,
  summary: string,
  image: string,
  images: any,
  publish_date: Date,
  tags: string[],
  priority: Boolean,
  starred: Boolean,
  deleted: Boolean,
  text: string,
  approved: Boolean,
  isActive: Boolean,
  seed: any
) => {
  const slug = title
    .toLowerCase()
    .replace(/\s/g, "-")
    .normalize("NFD")
    .replace(
      /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
      "$1"
    )
    .normalize();

  let newImages = [];
  images.forEach((image) => {
    return getImageArrayBuffer(image.preview).then((arrayBuffer) =>
      newImages.push(
        PostImage.create(
          undefined,
          "main",
          slug,
          {
            data: arrayBuffer,
            contentType: "image/jpeg",
          },
          undefined
        )
      )
    );
  });

  let newPost = {
    _id: undefined,
    title: title ? title : "",
    owner_id: owner_id ? owner_id : "",
    subtitle: subtitle ? subtitle : "",
    summary: summary ? summary : "",
    tags: tags ? tags : [],
    images: newImages ? newImages : [],
    text: text ? text : JSON.stringify(text),
    publish_date: publish_date,
    starred: false,
    approved: false,
    deleted: false,
    slug: slug,
    image: image,
    isActive: isActive,
  };

  return async (dispatch) => {
    try {
      const res = await createPost.run(newPost);
      toastr.success("Se ha guardado el post");
      dispatch({
        type: "ADD_POST",
        _id: res._id,
        title: res.title,
        owner_id: res.owner_id,
        subtitle: res.subtitle,
        summary: res.summary,
        tags: res.tags,
        images: res.images,
        text: res.text,
        publish_date: res.publish_date,
        starred: res.starred,
        approved: res.approved,
        deleted: res.deleted,
        slug: res.slug,
        image: res.image,
      });
      seed();
    } catch (error) {
      console.log(error);
      toastr.error("Hubo un problema");
      dispatch({
        type: "ADD_POST",
        _id: _id ? _id : "",
        title: title ? title : "",
        owner_id: owner_id ? owner_id : "",
        subtitle: subtitle ? subtitle : "",
        summary: summary ? summary : "",
        tags: tags ? tags : [],
        images: [],
        text: text ? text : JSON.stringify(text),
        publish_date: publish_date,
        starred: false,
        approved: false,
        deleted: false,
        slug: title.replace(" ", "-"),
        image: "https://randomuser.me/api/portraits/thumb/men/" + _id + ".jpg",
      });
    }
  };
};

export const updatePost = (_id, field, value, selectedPost) => {
  const updatedPost = {
    _id: _id,
    title: field === "title" ? value : selectedPost.title,
    owner_id: field === "owner_id" ? value : selectedPost.owner_id,
    publish_date: field === "publish_date" ? value : selectedPost.publish_date,
    subtitle: field === "subtitle" ? value : selectedPost.subtitle,
    summary: field === "summary" ? value : selectedPost.summary,
    tags: field === "tags" ? value : selectedPost.tags,
    images: field === "images" ? value : selectedPost.images,
    text: field === "text" ? value : selectedPost.text,
    starred: field === "starred" ? value : selectedPost.starred,
    deleted: field === "deleted" ? value : selectedPost.deleted,
    slug: field === "slug" ? value : selectedPost.slug,
    image: field === "image" ? value : selectedPost.image,
    approved: field === "approved" ? value : selectedPost.approved,
  };

  return (dispatch) => {
    return postModifier
      .run(updatedPost)
      .then((res) => {
        toastr.success("Contenido Actualizado");
        dispatch({
          type: "UPDATE_POST",
          _id: _id,
          field: field,
          value: value,
        });
      })
      .catch((err) => {
        toastr.error("No se puedo actualizar el contenido");
        console.log(err);
      });
  };
};

export const updateSelectedPost = (selectedPost) => {
  return (dispatch) => {
    return postModifier
      .run(selectedPost)
      .then((res) => {
        toastr.success("Contenido Actualizado");
        dispatch({
          type: "UPDATE_SELECTED_POST",
          selectedPost: selectedPost,
        });
      })
      .catch((err) => {
        toastr.error("No se puedo actualizar el contenido");
        console.log(err);
      });
  };
};

export const postDetails = (_id) => {
  return {
    type: "POST_DETAILS",
    _id,
  };
};

export const assignPostTag = (_id, tag) => ({
  type: "ASSIGN_POST_TAG",
  _id,
  tag,
});

export const setEditPostFlag = (flag) => ({
  type: "EDIT_POST",
  flag,
});

export const setPostVisibilityFilter = (filter) => ({
  type: "SET_VISIBILITY_FILTER",
  filter,
});

export const postSearch = (searchTerm) => ({
  type: "FILTER_POST",
  payload: searchTerm,
});

export const togglePost = (_id) => ({
  type: "TOGGLE_POST",
  _id,
});

export const toggleStarredPost = (_id) => ({
  type: "TOGGLE_STARRED_POST",
  _id,
});

export const toggleApprovePost = (_id) => ({
  type: "TOGGLE_APPROVE_POST",
  _id,
});

export const togglePriorityPost = (_id) => ({
  type: "TOGGLE_PRIORITY_POST",
  _id,
});

export const deletePost = (_id) => ({
  type: "DELETE_POST",
  _id,
});

export const destroyPost = (_id) => {
  return function (dispatch) {
    return postDestroyer
      .run(_id)
      .then((response) => {
        dispatch({
          type: "DELETE_POST",
          _id,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: "DELETE_POST",
          _id,
        });
      });
  };
};

export function fetchPostsPage(pageSize: number, pageNumber: number) {
  return async function (dispatch) {
    try {
      const data = await getPostsPage.run(pageSize, pageNumber);
      console.log("data", data);
      dispatch({
        type: "GET_POSTS_PAGE",
        posts: data,
      });
    } catch (err) {
      console.log("INDEX ERRROR");
      console.log(err);
      let errorMessage = "No se pudo obtener la lista de posts";
      if (err.data) {
        console.log("HAY ERROR DATA");
        console.log(err);
        errorMessage = err.status + " " + err.statusText;
      } else {
        if (err.message) {
          console.log("HAY ERROR MESSAGE");
          errorMessage = err.message;
        } else {
          console.log("ERROR GENERICO INDEX");
        }
      }
      toastr.error(errorMessage);
      dispatch({
        type: "GET_POSTS_PAGE",
        posts: [],
      });
    }
  };
}

export function fetchPosts() {
  return function (dispatch) {
    return indexPosts
      .run()
      .then((data) => {
        dispatch({
          type: "GET_ALL_POSTS",
          posts: data,
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        let errorMessage = "No se pudo obtener la lista de posts";
        if (err.data) {
          console.log("HAY ERROR DATA");
          console.log(err);
          errorMessage = err.status + " " + err.statusText;
        } else {
          if (err.message) {
            console.log("HAY ERROR MESSAGE");
            errorMessage = err.message;
          } else {
            console.log("ERROR GENERICO INDEX");
          }
        }
        toastr.error(errorMessage);
        dispatch({
          type: "GET_ALL_POSTS",
          posts: [],
        });
      });
  };
}

export function fetchPostsCount() {
  return function (dispatch) {
    return postsCountGetter
      .run()
      .then((data) => {
        dispatch({
          type: "GET_POSTS_COUNT",
          postsCount: data,
        });
      })
      .catch((err) => {
        console.log("INDEX ERRROR");
        console.log(err);
        let errorMessage = "No se pudo obtener la lista de posts";
        if (err.data) {
          console.log("HAY ERROR DATA");
          console.log(err);
          errorMessage = err.status + " " + err.statusText;
        } else {
          if (err.message) {
            console.log("HAY ERROR MESSAGE");
            errorMessage = err.message;
          } else {
            console.log("ERROR GENERICO INDEX");
          }
        }
        toastr.error(errorMessage);
        dispatch({
          type: "GET_POSTS_COUNT",
          postsCount: 0,
        });
      });
  };
}

export const postVisibilityFilter = {
  SHOW_ALL: "SHOW_ALL",
  OPEN_POST: "OPEN_POST",
  POST_PRODUCTION_POST: "POST_PRODUCTION_POST",
  COMPLETED_POST: "COMPLETED_POST",
  TECHNICAL_SUPPORT_POST: "TECHNICAL_SUPPORT_POST",
  STARRED_POST: "STARRED_POST",
  PRIORITY_POST: "PRIORITY_POST",
  PROD_POST: "PROD_POST",
  ADMIN_POST: "ADMIN_POST",
  SALES_POST: "SALES_POST",
  PROM_POST: "PROM_POST",
  EVENTS_POST: "EVENTS_POST",
  OPE_POST: "OPE_POST",
  UIUX_POST: "UIUX_POST",
  COLLECTIONS_POST: "COLLECTIONS_POST",
  FRONTEND_POST: "FRONTEND_POST",
  HEALTH_POST: "HEALTH_POST",
  SEARCH_POST: "SEARCH_POST",
  DELETED_POST: "DELETED_POST",
  CULTURE_POST: "CULTURE_POST",
  TECHNOLOGY_POST: "TECHNOLOGY_POST",
  ENTREPRENEURSHIP_POST: "ENTREPRENEURSHIP_POST",
  ENTERTAINMENT_POST: "ENTERTAINMENT_POST",
  SPORTS_POST: "SPORTS_POST",
  CINEMA_POST: "CINEMA_POSTS",
  FRANCHISE_POST: "FRANCHISES_POST",
  UNAPPROVED_POST: "UNAPPROVED_POST",
  EDUCATION_POST: "EDUCATION_POST",
};
