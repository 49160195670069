/**
 * Implements User repository interface with axios
 * promise based HTTP client.
 */

import { Post } from "../../domain/Post";
import { Tag } from "../../domain/Tag";
import { PostRepository } from "../../domain/PostRepository";
import axios from "axios";
import { apiUrl } from "../../../../config";

const baseURL = `https://posts.tnoradio.com/api/posts/`;
//const baseURL = `http://localhost:9000/api/posts/`;

export class AxiosPostRepository implements PostRepository {
  public async index(): Promise<Post[]> {
    try {
      const response = await axios.get(baseURL + "index");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async indexTags(): Promise<Tag[]> {
    try {
      const response = await axios.get(baseURL + "tags/index");
      return response.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async save(post: Post): Promise<Post> {
    try {
      const response = await axios.post(baseURL + "save", post);
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      //  console.error(error);
      if (error.response) {
        console.error(error.response);
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }
  public async saveTag(tag: Tag): Promise<Tag> {
    try {
      const response = await axios.post(baseURL + "tags/save", tag);
      if (response !== undefined && response.data !== undefined) {
        return response.data;
      } else {
        return Promise.resolve(null);
      }
    } catch (error) {
      //  console.error(error);
      if (error.response) {
        console.error(error.response);
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async updatePost(updatedPost: any): Promise<Post> {
    try {
      const result = await axios.patch(
        baseURL + "update/" + updatedPost._id,
        updatedPost
      );
      return Promise.resolve(result.data);
    } catch (error) {
      // console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async destroyPost(id: string): Promise<Post> {
    try {
      const response = await axios.delete(baseURL + "destroy/" + id);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async uploadImage(
    file,
    slug,
    location,
    name,
    type,
    postId,
    imageName
  ): Promise<any> {
    const formData = new FormData();
    formData.set("image", file, imageName);
    formData.set("slug", slug);
    formData.set("location", location);
    formData.set("type", type);
    formData.set("owner", postId);
    formData.set("name", name);

    try {
      const response = await axios.post(baseURL + "uploadtodb", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  }
  public async postImage(
    file,
    slug,
    location,
    name,
    type,
    postId,
    imageName
  ): Promise<any> {
    const formData = new FormData();
    formData.set("image", file, imageName);
    formData.set("slug", slug);
    formData.set("location", location);
    formData.set("type", type);
    formData.set("owner", postId);
    formData.set("name", name);

    try {
      const response = await axios.post(baseURL + "upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  public async updateImageInDB(
    image,
    name,
    type,
    slug,
    location,
    imageId,
    fileId
  ): Promise<any> {
    console.log(name);
    const formData = new FormData();
    formData.set("image", image, slug + ".jpg");
    formData.set("name", name);
    formData.set("type", type);
    formData.set("slug", slug);
    formData.set("location", location);
    formData.set("owner", imageId);
    formData.set("fileId", fileId);

    try {
      const response = await axios.patch(baseURL + "updateimage", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      return response.data;
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  public async getPostImage(name: string, slug: string) {
    let res;
    try {
      res = await axios.get(baseURL + "imagefromdb/" + name + "/" + slug);
      return res.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  public async getPostDetail(slug) {
    let res;
    try {
      res = await axios.get(baseURL + "details/" + slug + "/");
      return res.data;
    } catch (error) {
      console.error(error);
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          return Promise.reject(errorObject);
        } else {
          return Promise.reject(error);
        }
      }
    }
  }

  async getPostsPage(pageSize: number, page: number): Promise<Post[]> {
    try {
      const response = await axios.get(
        baseURL + "index/" + pageSize + "/" + page
      );
      //console.log(response);
      return response.data;
    } catch (error) {
      // console.log(error);
      if (error.response) {
        //console.log("ERROR.RESPONSE");
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          // console.log("ERROR OBJECT " + errorObject);
          return Promise.reject(errorObject);
        } else {
          //  console.log("ERROR GENERICO ");
          return Promise.reject(error);
        }
      }
    }
  }

  async getPostsCount(): Promise<number> {
    try {
      const response = await axios.get(baseURL + "count");
      return Promise.resolve(response.data);
    } catch (error) {
      // console.log(error);
      if (error.response) {
        //console.log("ERROR.RESPONSE");
        return Promise.reject(error.response);
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        if (errorObject.message) {
          // console.log("ERROR OBJECT " + errorObject);
          return Promise.reject(errorObject);
        } else {
          //  console.log("ERROR GENERICO ");
          return Promise.reject(error);
        }
      }
    }
  }
}
