import { PostRepository } from "../../../domain/PostRepository";
import { Post } from "../../../domain/Post";

export class AllPostsGetter {
  private repository: PostRepository;

  constructor(repository: PostRepository) {
    this.repository = repository;
  }

  async run(): Promise<Post[]> {
    return await this.repository.index();
  }
}
