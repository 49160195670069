import PostImage from "./PostImage";

export class Post {
  _id: string;
  title: string;
  owner_id: string; // user id of owner
  subtitle: string;
  summary: string;
  image: string;
  images: PostImage[];
  publish_date: Date;
  tags: string[];
  starred: Boolean;
  deleted: Boolean;
  text: string;
  slug: string;
  approved: Boolean;

  constructor(
    _id: string,
    title: string,
    owner_id: string, // user id of owner
    subtitle: string,
    summary: string,
    image: string,
    images: PostImage[],
    publish_date: Date,
    tags: string[],
    starred: Boolean,
    deleted: Boolean,
    text: string,
    slug: string,
    approved: Boolean
  ) {
    this._id = _id;
    this.title = title;
    this.subtitle = subtitle;
    this.summary = summary;
    this.owner_id = owner_id;
    this.publish_date = publish_date;
    this.image = image;
    this.images = images;
    this.text = text;
    this.tags = tags;
    this.images = images;
    this.starred = starred;
    this.deleted = deleted;
    this.approved = approved;
    this.slug = slug;
  }

  static create(
    _id: string,
    title: string,
    owner_id: string, // user id of owner
    subtitle: string,
    summary: string,
    image: string,
    images: PostImage[],
    publish_date: Date,
    tags: string[],
    starred: Boolean,
    deleted: Boolean,
    text: string,
    slug: string,
    approved: Boolean
  ) {
    return new Post(
      _id,
      title,
      owner_id,
      subtitle,
      summary,
      image,
      images,
      publish_date,
      tags,
      starred,
      deleted,
      text,
      slug,
      approved
    );
  }
}

export class TextBlock {
  data: Object;
  depth: number;
  entityRanges: [];
  inlineStyleRanges: InlineStyleRanges[];
  key: string;
  text: string;
  type: string;
}

export class InlineStyleRanges {
  length: number;
  offset: number;
  style: string;
}
