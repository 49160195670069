import { Post } from "../../../domain/Post";
import { PostRepository } from "../../../domain/PostRepository";
import { UpdatePostRequest } from "./UpdatePostRequest";

export class PostModifier {
  private repository: PostRepository;
  request: UpdatePostRequest;

  constructor(repository: PostRepository) {
    this.repository = repository;
  }

  async run(request: UpdatePostRequest): Promise<Post> {
    const postUpdate = Post.create(
      request._id,
      request.title,
      request.owner_id,
      request.subtitle,
      request.summary,
      request.image,
      request.images,
      request.publish_date,
      request.tags,
      request.starred,
      request.deleted,
      request.text,
      request.slug,
      request.approved
    );

    return await this.repository.updatePost(postUpdate);
  }
}
