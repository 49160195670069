import { Post } from "../../../domain/Post";
import { PostRepository } from "../../../domain/PostRepository";
import { PostRequest } from "../../PostRequest";

export class PostCreator {
  private repository: PostRepository;

  constructor(repository: PostRepository) {
    this.repository = repository;
  }

  async run(request: PostRequest): Promise<Post> {
    const post = Post.create(
      request._id,
      request.title,
      request.owner_id,
      request.subtitle,
      request.summary,
      request.image,
      request.images,
      request.publish_date,
      request.tags,
      request.starred,
      request.deleted,
      request.text,
      request.slug,
      request.approved
    );

    return await this.repository.save(post);
  }
}
