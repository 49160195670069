export default class PostImage {
  _id: string;
  imageName: string;
  imageUrl: string;
  file: {
    data: any;
    contentType: string;
  };
  owner: string;

  constructor(
    _id: string,
    imageName: string,
    imageUrl: string,
    file: { data: any; contentType: string },
    owner: string
  ) {
    this._id = _id;
    this.imageName = imageName;
    this.imageUrl = imageUrl;
    this.file = file;
    this.owner = owner;
  }

  static create(
    _id: string,
    imageName: string,
    imageUrl: string,
    file: { data: any; contentType: string },
    owner: string
  ) {
    return new PostImage(_id, imageName, imageUrl, file, owner);
  }
}
