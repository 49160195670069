import { PostRepository } from "../../../domain/PostRepository";
import { Post } from "../../../domain/Post";

export class PagePostsGetter {
  private repository: PostRepository;

  constructor(repository: PostRepository) {
    this.repository = repository;
  }

  async run(pageSize: number, pageNumber: number): Promise<Post[]> {
    return await this.repository.getPostsPage(pageSize, pageNumber);
  }
}
