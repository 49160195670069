import { PostRepository } from "../../../domain/PostRepository";
//import { DeleteUserRequest } from './DeleteUserRequest'
import { Post } from "../../../domain/Post";

export class PostDestroyer {
  private repository: PostRepository;

  constructor(repository: PostRepository) {
    this.repository = repository;
  }

  async run(_id: string): Promise<Post | Error> {
    //ALGO EXTRAÑO SUCEDE, EL REQUEST RETORNA DIRECTO EL ID SIN
    //ENTRAR AL CAMPO
    try {
      console.log(_id);

      return await this.repository.destroyPost(_id);
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
