import { PostRepository } from "../../../domain/PostRepository";

export class PostsCountGetter {
  private repository: PostRepository;

  constructor(repository: PostRepository) {
    this.repository = repository;
  }

  async run(): Promise<number> {
    return await this.repository.getPostsCount();
  }
}
